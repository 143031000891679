// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-g-challenge-jsx": () => import("./../../../src/pages/g-challenge.jsx" /* webpackChunkName: "component---src-pages-g-challenge-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movley-jsx": () => import("./../../../src/pages/movley.jsx" /* webpackChunkName: "component---src-pages-movley-jsx" */),
  "component---src-pages-nimbus-jsx": () => import("./../../../src/pages/nimbus.jsx" /* webpackChunkName: "component---src-pages-nimbus-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pear-jsx": () => import("./../../../src/pages/pear.jsx" /* webpackChunkName: "component---src-pages-pear-jsx" */),
  "component---src-pages-pool-2-jsx": () => import("./../../../src/pages/pool-2.jsx" /* webpackChunkName: "component---src-pages-pool-2-jsx" */),
  "component---src-pages-pool-jsx": () => import("./../../../src/pages/pool.jsx" /* webpackChunkName: "component---src-pages-pool-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */)
}

